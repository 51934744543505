import { Menu } from './Menu';
import { mealPrice, Pricing } from './Pricing';
import { MealCategory } from './UserSettings';

export enum OrderStatus {
  Unpaid = 'unpaid',
  Pending = 'pending',
  Paid = 'paid',
  InCart = 'in_cart',
}

export interface Order {
  id?: number;
  date: string;
  breakfast: MealCategory;
  breakfast_old?: MealCategory;
  breakfast_amount?: number;
  breakfast_amount_old?: number;
  breakfast_pricing?: mealPrice;
  breakfast_pricing_old?: mealPrice;
  lunch: MealCategory;
  lunch_old?: MealCategory;
  lunch_amount?: number;
  lunch_amount_old?: number;
  lunch_pricing?: mealPrice;
  lunch_pricing_old?: mealPrice;
  snack?: MealCategory;
  snack_old?: MealCategory;
  snack_amount?: number;
  snack_amount_old?: number;
  snack_pricing?: mealPrice;
  snack_pricing_old?: mealPrice;
  afternoon_meal?: MealCategory;
  afternoon_meal_old?: MealCategory;
  afternoon_meal_amount?: number;
  afternoon_meal_amount_old?: number;
  afternoon_meal_pricing?: mealPrice;
  afternoon_meal_pricing_old?: mealPrice;
  menu?: Menu;
  pricing?: Pricing;
  goverment_discount_price?: number;
  status?: OrderStatus;
  total?: number;
  isHolidays?: boolean;
}

export interface AdminOrder {
  date: string;
  name: string;
  surname: string;
  userId: number;
  breakfast: MealCategory;
  breakfast_amount?: number;
  lunch: MealCategory;
  lunch_amount?: number;
  snack?: MealCategory;
  snack_amount?: number;
  afternoon_meal: MealCategory;
  afternoon_meal_amount?: number;
}

export interface AdminDateOrder {
  [date: string]: AdminOrder[];
}
